import React, { useContext, useEffect, useState } from 'react'
import {
  Context,
  getPatientPriorities,
  getCancelledCases,
  getPhysicians,
  setPhysicianAction,
  setPriorityAction,
  setStatusAction,
  savePolicyPreference,
  resetNextLink,
  setPolicyPickerOpenAction,
  setConfirmationModalAction,
  setArrayOfPatientsToSendFormToAll
} from '../../context/Context'
import { Context as TabContext } from '../../context/TabContext'
import { Button, FilterBadge } from 'docspera-components'
import PolicyPicker from '../PolicyPicker/PolicyPicker'
import { labeledStyles } from '../../styles'
import './HorizontalPanel.scss'

const HorizontalPanel = () => {
  const { state, dispatch } = useContext(Context)
  const { state: tabState } = useContext(TabContext)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    dispatch(setPolicyPickerOpenAction(isOpen))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (state.physicians === null && !state.isLoading) {
      getPhysicians(state, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.physicians && state.physicians.length > 0) {
      if (tabState.activeTab === 'Cancelled (Form Not Sent)' && state.physicians) {
        getCancelledCases(state, dispatch, 'altered')
       }
       else if (tabState.activeTab === 'Already Rebooked') {
        getPatientPriorities(state, dispatch, 'altered', 'Already Rebooked')
      }
        else {
        getPatientPriorities(state, dispatch, 'altered')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.physicians,
    state.sorting.sortBy,
    state.sorting.sort,
    state.filters.physician,
    state.filters.priority,
    state.filters.surveyCompletion,
    state.filters.status
  ])

  useEffect(() => {
    resetNextLink(dispatch)
    if (state.physicians && state.physicians.length > 0) {
      if (tabState.activeTab === 'Cancelled (Form Not Sent)' && state.physicians) {
        getCancelledCases(state, dispatch)
      } else if (tabState.activeTab === 'Already Rebooked') {
        getPatientPriorities(state, dispatch, false, 'Already Rebooked')
      } else {
        getPatientPriorities(state, dispatch)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabState.activeTab
  ])

  const handleChange = (value) => {
    savePolicyPreference(value, state, dispatch)
  }

  const handleSendToAllClick = () => {
    if (state.cancelledCases) {
      setArrayOfPatientsToSendFormToAll(state.cancelledCases, dispatch)
      dispatch(setConfirmationModalAction(true))
    }
  }

  const triggerNavigation = () => {
    let url
    let targetOrigin
    console.log('window.location.href (triggerNavigation()):', window.location.href)

    // LOCAL
    if (window.location.href.includes('intelligent-scheduler.docspera.localhost')) {
      targetOrigin = 'https://docspera.localhost'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://docspera.localhost/patientpath/rebook'
      } else {
        url = 'https://docspera.localhost/practice/dashboard/rebook/patients'
      }

      // STAGING (DocSpera)
    } else if (window.location.href.includes('intelligent-scheduler.d4.docvisor')) {
      targetOrigin = 'https://docvisor.com'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://velys.d4.docvisor.com/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // STAGING (DocVisor x Velys -> daryl+velysphysician@docspera.com)
    } else if (window.location.href.includes('intelligent-scheduler.velys.d4.docvisor')) {
      targetOrigin = 'https://velys.d4.docvisor.com'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://velys.d4.docvisor.com/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // STAGING (VELYS DEV)
    } else if (window.location.href.includes('intelligent-scheduler.d4.docvisor')) {
      targetOrigin = 'https://velys.d4.docvisor.com'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://velys.d4.docvisor.com/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // STAGING II (Velys SIT)

    } else if (window.location.href.includes('intelligent-scheduler.velys.docharp')) {
      targetOrigin = 'https://velys.docharp.com'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://velys.docharp.com/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // VELYS SIT
    } else if (window.location.href.includes('velys.docvisor.dev')) {
      targetOrigin = 'https://carecoordination.velys.docvisor.dev'
      if (window.location.href.includes('skin=velys')) {
        targetOrigin = 'https://velys.d4.docvisor.com'
        url = 'https://carecoordination.velys.docvisor.dev/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // STAGING (-qa)
    } else if (window.location.href.includes('intelligent-scheduler.velys-qa.d4.docvisor')) {
      targetOrigin = 'https://velys-qa.d4.docvisor.com'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://velys-qa.d4.docvisor.com/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
      // PROD (VELYS)
    } else if (window.location.host === 'intelligent-scheduler.carecoordination.velys.jnj') {
      targetOrigin = 'https://carecoordination.velys.jnj'
      if (window.location.href.includes('skin=velys')) {
        url = 'https://carecoordination.velys.jnj/patientpath/rebook'
      } else {
        url = 'https://docvisor.com/practice/dashboard/rebook/patients'
      }
    } else {
      // no origin to send to
      console.log('no origin to receive from')
      return
    }

    console.log('target origin: ', targetOrigin)

    let targetWindow = window.parent
    if (targetWindow) {
      targetWindow.postMessage({ type: 'reroute-to-rebook', payload: url }, targetOrigin)
    }
  }

  const ifPatientPath = () => {
    if (window.location.href.includes('patientpath=true')) {
      return true
    } else {
      return false
    }
  }

  return (
    <div
      id='horizontal-panel'
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', marginLeft: 25 }}>
          {state.policies && tabState.activeTab === 'Rebooking' && (
            <PolicyPicker
              sections={state.policies}
              value={state.policyPreference}
              setValue={handleChange}
              isOpen={isOpen}
              setOpen={setOpen}
              primaryColor={labeledStyles.policyPicker.primary}
              secondaryColor={labeledStyles.policyPicker.secondary}
              tertiaryColor={labeledStyles.policyPicker.tertiary}
              fontFamily={labeledStyles.policyPicker.fontFamily}
              labelFont={labeledStyles.policyPicker.labelFont}
              menuHeaderFontColor={labeledStyles.policyPicker.menuHeaderFontColor}
              menuHeaderBGColor={labeledStyles.policyPicker.menuHeaderBGColor}
              inputBorder={labeledStyles.policyPicker.inputBorder}
            />
          )}
          <div>
            <span
              style={{
                fontFamily: labeledStyles.filters.fontFamily,
                color: labeledStyles.policyPicker.dropdownLabelColor
              }}
            >
              Filter by:
            </span>
            <div id='filters'>
              <FilterBadge
                title={'Physician'}
                options={state.physicians}
                value={state.filters.physician}
                setValue={(value) =>
                  dispatch(
                    setPhysicianAction(value['Physician'] !== undefined ? value['Physician'] : [])
                  )
                }
                permanantlyOpen={false}
                fontFamily={labeledStyles.filters.fontFamily}
                badgeBackground={labeledStyles.filters.badgeBackground}
                border={labeledStyles.filters.border}
                badgeFontColor={labeledStyles.filters.badgeFontColor}
                checkboxColor={labeledStyles.filters.checkboxColor}
                checkboxBorder={labeledStyles.filters.checkboxBorder}
                borderRadius={labeledStyles.filters.borderRadius}
              />
              {tabState.activeTab === 'Rebooking' && (
                <FilterBadge
                  title={'Priority'}
                  options={[
                    { id: 'high', name: 'High' },
                    { id: 'med', name: 'Medium' },
                    { id: 'low', name: 'Low' }
                  ]}
                  value={state.filters.priority}
                  setValue={(value) =>
                    dispatch(
                      setPriorityAction(value['Priority'] !== undefined ? value['Priority'] : [])
                    )
                  }
                  permanantlyOpen={false}
                  fontFamily={labeledStyles.filters.fontFamily}
                  badgeBackground={labeledStyles.filters.badgeBackground}
                  border={labeledStyles.filters.border}
                  badgeFontColor={labeledStyles.filters.badgeFontColor}
                  checkboxColor={labeledStyles.filters.checkboxColor}
                  checkboxBorder={labeledStyles.filters.checkboxBorder}
                  borderRadius={labeledStyles.filters.borderRadius}
                />
              )}
              {tabState.activeTab === 'Rebooking' && (
                <FilterBadge
                  title='Status'
                  options={[
                    {
                      id: 1,
                      customDiv: (
                        <img
                          src='../../images/incomplete-survey.svg'
                          style={{ width: 20, marginLeft: 3, marginRight: 5 }}
                          alt='survey incomplete icon'
                        />
                      ),
                      name: 'Form Sent & Not Completed'
                    },
                    {
                      id: 2,
                      customDiv: (
                        <img
                          src='../../images/complete-survey.svg'
                          style={{ width: 20, marginLeft: 3, marginRight: 5 }}
                          alt='survey complete icon'
                        />
                      ),
                      name: 'Form Complete & Rebookable'
                    }
                  ]}
                  value={state.filters.status}
                  setValue={(value) => {
                    dispatch(setStatusAction(value['Status'] !== undefined ? value['Status'] : []))
                  }}
                  permanantlyOpen={false}
                  fontFamily={labeledStyles.filters.fontFamily}
                  badgeBackground={labeledStyles.filters.badgeBackground}
                  border={labeledStyles.filters.border}
                  badgeFontColor={labeledStyles.filters.badgeFontColor}
                  checkboxColor={labeledStyles.filters.checkboxColor}
                  checkboxBorder={labeledStyles.filters.checkboxBorder}
                  borderRadius={labeledStyles.filters.borderRadius}
                />
              )}
            </div>
          </div>
        </div>

        {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
          <div id='send-button'>
            <span
              style={{
                fontFamily: labeledStyles.filters.fontFamily,
                color: labeledStyles.policyPicker.dropdownLabelColor,
                marginBottom: 10
              }}
            >
              Send Form:
            </span>
            <Button
              id='bulk-send-btn'
              content={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='../../images/send_icon.svg' alt='send icon' style={{ width: 16 }} />
                  <span style={{ fontFamily: labeledStyles.buttons.fontFamily, marginLeft: 10 }}>
                    to All Cancelled Patients
                  </span>
                </div>
              }
              size='medium'
              background={labeledStyles.buttons.background}
              hoverStyles={{
                background: labeledStyles.buttons.hoverBackground,
                borderColor: labeledStyles.buttons.hoverBorderColor,
                color: labeledStyles.buttons.hoverColor
              }}
              borderColor={labeledStyles.buttons.borderColor}
              color={labeledStyles.buttons.color}
              fontFamily={labeledStyles.buttons.fontFamily}
              onClick={() => handleSendToAllClick()}
            />
          </div>
        )}
      </div>

      {ifPatientPath() && (
          <div id='rebook-button' style={{marginRight: 25}}>
            <span style={{ fontFamily: labeledStyles.filters.fontFamily }}>Done Prioritizing?</span>
            <Button
              id='rebook-btn'
              content='Rebook'
              size='medium'
              background={labeledStyles.buttons.background}
              hoverBackground={labeledStyles.buttons.hoverBackground}
              borderColor={labeledStyles.buttons.borderColor}
              hoverBorderColor={labeledStyles.buttons.hoverBorderColor}
              color={labeledStyles.buttons.color}
              hoverColor={labeledStyles.buttons.hoverColor}
              fontFamily={labeledStyles.buttons.fontFamily}
              onClick={triggerNavigation}
            />
          </div>
        )}
    </div>
  )
}

export default HorizontalPanel
