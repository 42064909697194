import { groupBy, sortBy } from 'lodash'
import parse from 'parse-link-header'
import moment from 'moment'
import { getCookie } from '../helpers'
import axios from 'axios'

const instance = axios.create({
  baseURL: '/',
  withCredentials: true, // todo: make this configurable
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
})

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${getCookie('docsperajwt')}`
  return request
}

instance.interceptors.request.use((request) => requestHandler(request))

async function getConfigApi() {
  const url = '/config.json'
  return fetch(url, { method: 'GET' }).then((response) => {
    if (response.data) {
      return response.data
    } else {
      throw new Error('no config data')
    }
  })
}

async function getPoliciesApi() {
  const url = `/api/v1/intelligent-scheduling/policies`

  return instance
    .get(url)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data
          let categories = groupBy(
            sortBy(json, (policy) => policy.order),
            (policy) => policy.category
          )
          let policies = Object.keys(categories).map((category, index) => ({
            title: category,
            order: index,
            options: categories[category].map((categoryRow) => ({
              descriptions:
                categoryRow.description.length > 0 ? categoryRow.description.split('\n') : null,
              ...categoryRow
            }))
          }))
          resolve(policies)
        } else {
          reject()
        }
      })
    })
    .catch(() => {
      console.log('error') //.warn .write
      // return new Error() --> error class
    })
}

async function getPolicyPreferenceApi() {
  const url = `/api/v1/intelligent-scheduling/preferences/policy`
  // const url = `/policyPreference.json`

  return instance.get(url).then((response) => {
    return response.data
  })
}

async function savePolicyPreferenceApi(policyId) {
  const url = `/api/v1/intelligent-scheduling/preferences/policy`
  // const url = `/policy.json`

  return instance.put(url, { id: policyId }).then(() => {
    return true
  })
}

async function getPatientPrioritiesApi(
  policyId,
  userIds,
  priorityIds,
  surveyCompletionIds,
  statusIds,
  pageSize,
  url,
  sortBy,
  sortDirection,
  tab,
) {
  if (url === null || url === undefined) {
    url = `/api/v1/intelligent-scheduling/patients`
    let queryString = ''
    if (pageSize) queryString += `limit=${pageSize}&`
    if (policyId) queryString += `policyId=${policyId}&`

    // Filters
    if (userIds && Array.isArray(userIds))
      userIds.forEach((userId, idx) => {
        if (!isNaN(Number(userId))) {
          queryString += `userId[${idx}]=${userId}&` // normally should "url-encode" this but since its always integers its fine
        }
      })
    if (priorityIds && Array.isArray(priorityIds)) {
      priorityIds.forEach((priorityId, idx) => {
        queryString += `priority.priority[${idx}]=${priorityId}&`
      })
    }
    if (surveyCompletionIds && Array.isArray(surveyCompletionIds)) {
      surveyCompletionIds.forEach((completionId, idx) => {
        queryString += `survey.completion[${idx}]=${completionId}&`
      })
    }
    if (statusIds && Array.isArray(statusIds)) {
      statusIds.forEach((statusId, idx) => {
        queryString += `status[${idx}]=${statusId}&`
      })
    }

    // Sorting
    if (sortBy) {
      queryString += `sortBy=${encodeURI(sortBy)}&`
    }
    if (sortDirection) {
      queryString += `sort=${encodeURI(sortDirection)}&`
    }
    // trim if necessary
    if (queryString[queryString.length - 1] === '&') queryString = queryString.slice(0, -1) // trim off the last '&'
    url = url + '?' + queryString

    //search for already booked if tab is already booked
    if(tab === 'Already Rebooked') {
      url += `&status[0]=3`
    }
  }
  // else
  //   url = 'https://intelligent-scheduler.d4.docvisor.com' + url

  return instance.get(url).then((response) => {
    return new Promise((resolve, reject) => {
      if (response.data) {
        let nextPageUri = null
        // parse the response headers
        if (response.headers.link !== undefined) {
          let linkHeader = response.headers.link
          let links = parse(linkHeader)
          if (links.next !== undefined) {
            nextPageUri = links.next.url
          }
        }
        let json = response.data
        resolve({ json, nextPageUri })
      } else {
        throw new Error('no data in api response')
      }
    })
  })
}

async function getCancelledCasesApi(userIds, pageSize, url) {
  let queryString = ''
  if (url === null || url === undefined) {
  url = `/api/v1/intelligent-scheduling/cancellations`
  if (pageSize) queryString += `limit=${pageSize}&`
}

  // Physician Filter
  if (userIds && Array.isArray(userIds))
    userIds.forEach((userId, idx) => {
      if (!isNaN(Number(userId))) {
        queryString += `ownerId[${idx}]=${userId}&` // normally should "url-encode" this but since its always integers its fine
      }
    })

  // trim if necessary
  if (queryString[queryString.length - 1] === '&') queryString = queryString.slice(0, -1) // trim off the last '&'
  url = url + '?' + queryString

  return instance.get(url).then((response) => {
    return new Promise((resolve, reject) => {
      if (response.data) {
        let nextPageUri = null
        // parse the response headers
        if (response.headers.link !== undefined) {
          let linkHeader = response.headers.link
          let links = parse(linkHeader)
          if (links.next !== undefined) {
            nextPageUri = links.next.url
          }
        }
        let json = response.data
        resolve({ json, nextPageUri })
      } else {
        console.log('error in getting cancelled cases')
        throw new Error('no data in api response')
      }
    })
  })
}

async function setPatientPriorityApi(patientId, priority, policyId) {
  const url = `/api/v1/intelligent-scheduling/patients/${patientId}`
  return instance
    .put(url, {
      policyId: policyId,
      priority: priority
    })
    .then((response) => {
      return response.data
    })
}

async function getPhysiciansApi() {
  const url = `/api/v1/intelligent-scheduling/users`
  return instance
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      console.log('error obtaining physicisn')
    })
}

async function getSurveyApi(recipientId) {
  const url = `/api/v1/intelligent-scheduling/patients/${recipientId}/survey`
  return instance
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      console.log('error obtaining survey')
    })
}

async function getAvailabilityApi(physician, date) {
  let formattedDate = moment(date, 'YYYY-MM-DD hh:mm:ss').format('YYYYMMDD')
  const url = `/api/v1/intelligent-scheduling/users/${physician}/availability?date=${formattedDate}`
  return instance
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      console.log('error obtaining survey')
    })
}

async function getHeatCalendarAvailabilityApi(start, end, physician) {
  let formattedStart = moment(start).format('YYYYMMDD')
  let formattedEnd = moment(end).format('YYYYMMDD')
  const url = `/api/v1/intelligent-scheduling/users/${physician}/bookings?start_date=${formattedStart}&end_date=${formattedEnd}`
  return instance
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      console.log('error obtaining survey')
    })
}

async function rebookApi(url, newTime) {
  return instance
    .put(url, {
      start: newTime.start,
      end: newTime.end
    })
    .then((response) => {
      return response
    })
}

async function sendBulkSurveysApi(ids) {
  const url = '/rpc/v1/createSurveysFromCalendarIds'
  return instance.post(url, { calendar_ids: ids }).then((response) => {
    return response
  })
}

export {
  getConfigApi,
  getPoliciesApi,
  getPolicyPreferenceApi,
  savePolicyPreferenceApi,
  getPatientPrioritiesApi,
  getCancelledCasesApi,
  setPatientPriorityApi,
  getPhysiciansApi,
  getSurveyApi,
  getAvailabilityApi,
  getHeatCalendarAvailabilityApi,
  rebookApi,
  sendBulkSurveysApi
}
